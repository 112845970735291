<template>
  <div>
    <b-card id="admin-surveys" title="TUS Kadro Anketleri">
      <div style="min-height: 450px">
        <b-form-input placeholder="Anket filtrele" v-model="filterText" />

        <b-table
          responsive
          selectable
          select-mode="single"
          hover
          class="mt-2 odd-even-table"
          :per-page="resultsPerPage"
          :current-page="resultsCurrentPage"
          :items="filteredSurveys"
          :fields="surveyListTableFields"
          @row-selected="showSurveyDetails"
        >
          <template #cell(index)="data">
            <p
              :class="
                selectedSurvey && data.item.ts_id == selectedSurvey.ts_id
                  ? 'text-primary font-weight-bold'
                  : ''
              "
              style="margin: 0px"
            >
              {{ (resultsCurrentPage - 1) * resultsPerPage + data.index + 1 }}
            </p>
          </template>
          <template #cell(institution)="data">
            <p
              :class="
                selectedSurvey && data.item.ts_id == selectedSurvey.ts_id
                  ? 'text-primary font-weight-bold'
                  : ''
              "
              style="margin: 0px"
              v-html="intitutionName(data.item)"
            ></p>
          </template>
          <template #cell(profession)="data">
            <p
              :class="
                selectedSurvey && data.item.ts_id == selectedSurvey.ts_id
                  ? 'text-primary font-weight-bold'
                  : ''
              "
              style="margin: 0px"
            >
              {{ toTitleCase(data.item.profession) }}
            </p>
          </template>
          <template #cell(creation_time)="data">
            <p style="margin-bottom: 0px">
              {{ dateTimeToDayText(data.item.creation_time) }}
            </p>
          </template>
          <template #cell(question_count)="data">
            <p style="margin-bottom: 0px; margin-left: 10px">
              {{ data.item.user_answers.length }}
            </p>
          </template>
          <template #cell(email)="data">
            <p
              :class="
                selectedSurvey && data.item.ts_id == selectedSurvey.ts_id
                  ? 'text-primary font-weight-bold'
                  : ''
              "
              style="margin-bottom: 0px; margin-left: 10px"
            >
              {{ data.item.email }}
            </p>
          </template>
          <template #cell(full_name)="data">
            <p
              :class="
                selectedSurvey && data.item.ts_id == selectedSurvey.ts_id
                  ? 'text-primary font-weight-bold'
                  : ''
              "
              style="margin-bottom: 0px; margin-left: 10px"
            >
              {{ toTitleCase(data.item.full_name) }}
            </p>
          </template>
          <template #cell(approved)="data">
            <p
              :style="
                data.item.approved
                  ? 'margin-bottom: 0px; color: green;'
                  : 'margin-bottom: 0px; color: red;'
              "
            >
              {{ data.item.approved ? "Onaylandı" : "Onaylanmadı" }}
            </p>
          </template>
        </b-table>

        <div style="display: flex; margin: 20px 10px 20px 10px">
          <div style="flex-grow: 1">
            <p v-html="getSurveyListSummary()"></p>
          </div>
          <b-pagination
            v-if="filteredSurveys.length > resultsPerPage"
            v-model="resultsCurrentPage"
            :total-rows="filteredSurveys.length"
            :per-page="resultsPerPage"
          />
        </div>
      </div>
    </b-card>

    <div v-if="selectedSurvey">
      <b-alert
        v-if="getPreviousSurveyOfUser(selectedSurvey)"
        variant="danger"
        show
        style="margin-top: 15px; margin-bottom: 20px"
      >
        <h4 class="alert-heading">Dikkat!</h4>
        <div class="alert-body" style="padding: 15px">
          {{ selectedSurvey.full_name }} adlı kişinin
          {{
            dateTimeToDayText(
              getPreviousSurveyOfUser(selectedSurvey).creation_time
            )
          }}
          tarihinde doldurulmuş başka bir anketi mevcut!
        </div>
      </b-alert>
    </div>

    <b-card v-if="selectedSurvey" :title="selectedSurvey.full_name">
      <b-row style="margin: 30px 20px 0px 20px">
        <b-col xl="5">
          <p>
            <span class="text-primary font-weight-bold">Hediye Türü:</span>
            {{ selectedSurvey.gift_type }}
          </p>
          <b-form-textarea
            rows="3"
            max-rows="3"
            v-model="selectedSurvey.gift_code"
            placeholder=""
            autocomplete="off"
            style="margin-top: 15px"
          />

          <div
            v-if="!isUpdatedSurvey(selectedSurvey)"
            style="display: flex; margin: 30px 10px"
          >
            <div style="flex-grow: 1"></div>

            <b-button
              class=""
              variant="outline-primary"
              @click="updateGiftCode()"
            >
              Hediye Kodunu Güncelle
            </b-button>
          </div>
        </b-col>

        <b-col xl="1"></b-col>

        <b-col xl="6">
          <b-form-textarea rows="5" max-rows="5" v-model="message" />

          <b-row style="margin-top: 15px">
            <b-col xl="6">
              <b-form-input
                v-model="selectedSurvey.email"
                placeholder=""
                autocomplete="off"
              />
            </b-col>
            <b-col xl="6">
              <b-form-input
                v-model="selectedSurvey.phone"
                placeholder=""
                autocomplete="off"
              />
            </b-col>
          </b-row>

          <div style="display: flex; margin: 30px 0px 0px 0px">
            <div style="flex-grow: 1">Uzunluk: {{ message.length }}</div>
            <b-button class="mr-1 mb-1" variant="primary" @click="sendEmail()">
              Mail Gönder
            </b-button>
            <b-button class="mr-1 mb-1" variant="primary" @click="sendSms()">
              SMS Gönder
            </b-button>
            <b-button
              class="mr-1 mb-1"
              variant="outline-primary"
              @click="copyWhatsAppLink()"
            >
              WhatsApp
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card
      v-if="selectedSurvey"
      :title="
        toTitleCase(selectedSurvey.institution) +
        ' / ' +
        toTitleCase(selectedSurvey.profession) +
        ' (survey_id: ' +
        selectedSurvey.ts_id +
        ')'
      "
    >
      <div style="margin-bottom: 30px">
        <div
          v-for="section in getSectionsOfSelectedSurvey(selectedSurvey)"
          :key="section.title"
        >
          <h4 style="margin-top: 30px; margin-bottom: 10px">
            {{ section.title }}
          </h4>
          <b-table
            responsive
            hover
            :items="section.user_answers"
            :fields="userAnswersTableFields"
          >
            <template #cell(question)="data">
              <p
                v-if="
                  data.item.question == 'Çalışma bitişi' &&
                  !data.item.answer.includes('Anketi doldururken')
                "
                class="text-danger font-weight-bolder"
                style="margin-bottom: 0px; font-size: 1.5rem !important"
              >
                {{ data.item.question }}
              </p>
              <p v-else style="margin-bottom: 0px">
                {{ data.item.question }}
              </p>
            </template>
            <template #cell(answer)="data">
              <p
                v-if="
                  data.item.question == 'Çalışma bitişi' &&
                  !data.item.answer.includes('Anketi doldururken')
                "
                class="text-danger font-weight-bolder"
                style="margin-bottom: 0px; font-size: 1.5rem !important"
              >
                {{ data.item.answer }}
              </p>
              <p v-else style="margin-bottom: 0px">
                {{ data.item.answer ? data.item.answer : "..." }}
              </p>
            </template>
          </b-table>
        </div>
      </div>

      <div style="display: flex; margin: 30px 10px">
        <div class="font-weight-bold" style="flex-grow: 1; padding: 5px 10px">
          <div v-if="selectedSurvey.image">
            <p class="text-primary mt-2">
              {{ toTitleCase(selectedSurvey.institution) }}
            </p>
            <p class="text-primary">
              {{ toTitleCase(selectedSurvey.profession) }}
            </p>
            <img
              :src="
                selectedSurvey.image.startsWith('/9j')
                  ? 'data:image/jpg;base64,' + selectedSurvey.image
                  : selectedSurvey.image
              "
              style="max-height: 600px"
            />
          </div>
        </div>
        <div style="margin-top: 20px">
          <b-form-group
            v-if="!selectedSurvey.approved"
            label="Anket Dolduran"
            label-for="name"
            style="margin-bottom: 30px"
          >
            <b-form-input
              id="name"
              v-model="selectedSurvey.full_name"
              name="name"
            />
          </b-form-group>
          <b-button
            v-if="!selectedSurvey.approved"
            class="mx-1"
            variant="success"
            @click="$refs['modal-approve-survey'].show()"
          >
            Anketi Onayla
          </b-button>
          <b-button
            class="mx-1"
            variant="danger"
            @click="$refs['modal-delete-survey'].show()"
          >
            Anketi Sil
          </b-button>
        </div>
      </div>
    </b-card>

    <b-modal
      id="modal-approve-survey"
      ref="modal-approve-survey"
      hide-footer
      centered
      title="Anketi Onayla"
    >
      <p>Anket onaylanacak ve fotograf silinecek.</p>
      <p>Anketi giren kişinin ismini onaylamadan önce değiştirebilirsiniz.</p>

      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="secondary"
          @click="$refs['modal-approve-survey'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="approveSurvey()"
        >
          Onayla
        </b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-delete-survey"
      ref="modal-delete-survey"
      hide-footer
      centered
      title="Anketi Sil"
    >
      <p>Anket silinecek. Bu işlem geri alınamaz!</p>

      <hr style="margin: 20px 10px" />
      <div>
        <b-button
          class="mr-1"
          style="float: right"
          variant="primary"
          @click="$refs['modal-delete-survey'].hide()"
        >
          Kapat
        </b-button>
        <b-button
          class="mr-1"
          style="float: right"
          variant="danger"
          @click="deleteSurvey()"
        >
          Anketi Sil
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BTable,
  BPagination,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  BTabs,
  BTab,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BFormTextarea,
  },
  data() {
    return {
      filterText: "",
      surveyList: [],
      surveyListTableFields: [
        { key: "index", label: "" },
        { key: "institution", label: "Kurum" },
        { key: "profession", label: "Branş" },
        { key: "cadre_kind", label: "Tür" },
        { key: "creation_time", label: "Anket Tarihi" },
        { key: "question_count", label: "Soru Sayısı" },
        { key: "full_name", label: "Anketi Dolduran" },
        { key: "email", label: "E-Posta" },
        { key: "phone", label: "Telefon" },
        { key: "gift_type", label: "Hediye Türü" },
        { key: "gift_code", label: "Hediye Kodu" },
        { key: "approved", label: "Durumu" },
        { key: "details", label: "" },
      ],
      resultsPerPage: 10,
      resultsCurrentPage: 1,
      selectedSurvey: null,
      userAnswersTableFields: [
        { key: "question", label: "Soru" },
        { key: "answer", label: "Cevap" },
      ],
      message: "",
    };
  },
  watch: {
    filterText: function () {
      this.resultsCurrentPage = 1;
    },
  },

  computed: {
    filteredSurveys() {
      var filteredList = [];
      var filter = this.filterText.trim().toLocaleLowerCase("tr-TR");
      this.surveyList.forEach((survey) => {
        var content =
          survey.city +
          " " +
          survey.institution +
          " " +
          survey.profession +
          " " +
          survey.creation_time +
          " " +
          survey.full_name +
          " " +
          survey.email +
          " " +
          survey.phone +
          " " +
          survey.gift_type +
          " " +
          survey.gift_code;
        content = content.toLocaleLowerCase("tr-TR");
        if (!filter || content.includes(filter)) {
          filteredList.push(survey);
        }
      });
      return filteredList;
    },
  },

  methods: {
    isUpdatedSurvey(survey) {
      return (
        survey.update_code && survey.update_code.length > 3 && survey.updated
      );
    },

    getPreviousSurveyOfUser(selectedSurvey) {
      var previousSurvey;
      this.surveyList.forEach((survey) => {
        if (
          survey.creation_time != selectedSurvey.creation_time &&
          survey.full_name == selectedSurvey.full_name
        ) {
          previousSurvey = survey;
          return;
        }
      });
      return previousSurvey;
    },

    getSurveyListSummary() {
      if (!this.surveyList) {
        return "";
      }
      var premiumCount = 0;
      var freeCount = 0;
      var notApproved = 0;
      var cadreNames = [];
      this.surveyList.forEach((survey) => {
        var cadreDetails =
          survey.city +
          survey.institution +
          survey.profession +
          survey.cadre_kind;
        if (!cadreNames.includes(cadreDetails)) {
          cadreNames.push(cadreDetails);
        }
        if (survey.is_premium) {
          premiumCount++;
        } else {
          freeCount++;
        }
        if (survey.approved == 0) {
          notApproved++;
        }
      });
      var message = "Premium: " + premiumCount + ", Ücretsiz: " + freeCount;
      message += ", Toplam: " + (premiumCount + freeCount);
      message += "<br/>Farklı kadro sayısı: " + cadreNames.length;
      message += "<br/>Henüz onaylanmamış: " + notApproved;
      return message;
    },

    async sendSms() {
      if (!this.selectedSurvey && !this.selectedSurvey.phone) {
        this.showError("Bir hata oluştu ve SMS gönderilemedi");
        return;
      }
      var phone = this.selectedSurvey.phone;
      if (!phone.substring(0, 1) == "9" && !phone.substring(0, 1) == "0") {
        phone = "0" + phone;
      }

      var payload = {
        phone: phone,
        message: this.message,
      };
      var updated = await this.$store.dispatch(
        "adminCadreSurveys/sendSms",
        payload
      );
      if (updated) {
        this.showSuccess("SMS gönderildi.");
        if (this.message.includes("A.101")) {
          var messageText =
            "Hediye kodunuzu dilediğiniz A.101 mağazasında kasiyere göstererek kullanabilirsiniz.\n";
          payload = {
            phone: phone,
            message: messageText,
          };
          await this.$store.dispatch("adminCadreSurveys/sendSms", payload);
        }
      } else {
        this.showError("Bir hata oluştu ve SMS gönderilemedi.");
      }
    },

    async sendEmail() {
      if (!this.selectedSurvey) {
        this.showError("Bir hata oluştu ve email gönderilemedi");
        return;
      }
      var messageText = this.message;
      if (messageText.includes("A.101")) {
        messageText =
          messageText +
          "\n" +
          "Hediye kodunuzu dilediğiniz A.101 mağazasında kasiyere göstererek kullanabilirsiniz.\n";
      }
      var payload = {
        email: this.selectedSurvey.email,
        message: messageText.replaceAll("\n", "<br>"),
      };
      var updated = await this.$store.dispatch(
        "adminCadreSurveys/sendEmail",
        payload
      );
      if (updated) {
        this.showSuccess("Email gönderildi.");
      } else {
        this.showError("Bir hata oluştu ve email gönderilemedi.");
      }
    },

    async copyWhatsAppLink() {
      if (!this.selectedSurvey && !this.selectedSurvey.phone) {
        this.showError("Bir hata oluştu");
        return;
      }
      var phone = this.selectedSurvey.phone.replaceAll(" ", "");
      if (phone.length == 11) {
        phone = "9" + phone;
      }
      if (phone.length == 10) {
        phone = "90" + phone;
      }
      var copyText = "https://api.whatsapp.com/send/?phone=" + phone;
      await navigator.clipboard.writeText(copyText);
      this.showSuccess("WhatsApp bağlantısı kopyalandı.");
    },

    async updateGiftCode() {
      if (!this.selectedSurvey) {
        this.showError("Bir hata oluştu ve hediye kodu güncellenemedi");
        return;
      }
      var self = this;
      var giftCode = this.selectedSurvey.gift_code;
      giftCode = giftCode != null ? giftCode.trim() : "";
      var giftCodeAvailable = false;
      this.surveyList.forEach((survey) => {
        if (
          survey.email != self.selectedSurvey.email &&
          survey.gift_code &&
          giftCode == survey.gift_code.trim()
        ) {
          giftCodeAvailable = true;
        }
      });
      if (giftCodeAvailable) {
        this.showError("Hediye kodu başka bir anket için kullanıldı!");
        return;
      }
      var payload = {
        survey_id: this.selectedSurvey.ts_id,
        gift_code: this.selectedSurvey.gift_code,
      };
      var updated = await this.$store.dispatch(
        "adminCadreSurveys/updateGiftCode",
        payload
      );
      if (updated) {
        this.showSuccess("Hediye kodu güncellendi");
        var giftType = this.selectedSurvey.gift_type.toLocaleLowerCase("tr-TR");
        if (giftType && giftType.includes("101")) {
          this.message =
            "Anketiniz onaylandı. Desteğiniz için teşekkür ederiz.\n\n" +
            this.selectedSurvey.gift_type +
            "\n" +
            this.selectedSurvey.gift_code +
            "\n";
        }
      } else {
        this.showError("Bir hata oluştu ve hediye kodu güncellenemedi");
      }
      this.selectedSurvey = await store.dispatch(
        "adminCadreSurveys/fetchSurvey",
        this.selectedSurvey.ts_id
      );
      this.surveyList.forEach((survey) => {
        if (survey.email == self.selectedSurvey.email) {
          survey.gift_code = self.selectedSurvey.gift_code;
        }
      });
    },

    showError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Uyarı!",
          icon: "BellIcon",
          text: message,
          variant: "danger",
        },
      });
    },

    showSuccess(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "İşlem Başarılı!",
          icon: "BellIcon",
          text: message,
          variant: "success",
        },
      });
    },

    async approveSurvey() {
      this.$refs["modal-approve-survey"].hide();
      var payload = {
        survey_id: this.selectedSurvey.ts_id,
        full_name: this.selectedSurvey.full_name,
      };
      var self = this;
      var response = await this.$store.dispatch(
        "adminCadreSurveys/approveSurvey",
        payload
      );
      if (response) {
        self.selectedSurvey.image = null;
        self.selectedSurvey.approved = true;
        self.surveyList.forEach((srv) => {
          if (srv.ts_id == self.selectedSurvey.ts_id) {
            srv.approved = true;
            srv.full_name = self.selectedSurvey.full_name;
          }
        });
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Anket onaylandı.",
            variant: "success",
          },
        });
      } else {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Bir sorun oluştu ve anket onaylanamadı!",
            variant: "danger",
          },
        });
      }
    },

    async deleteSurvey() {
      this.$refs["modal-delete-survey"].hide();
      var surveyId = this.selectedSurvey.ts_id;
      var self = this;
      var response = await this.$store.dispatch(
        "adminCadreSurveys/deleteSurvey",
        surveyId
      );
      if (response) {
        self.selectedSurvey = null;
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "İşlem Başarılı!",
            icon: "BellIcon",
            text: "Anket silindi.",
            variant: "success",
          },
        });
        await store.dispatch("adminCadreSurveys/fetchSurveys");
        self.surveyList = store.state.adminCadreSurveys.surveyList;
      } else {
        self.$toast({
          component: ToastificationContent,
          props: {
            title: "Hay aksi!",
            icon: "BellIcon",
            text: "Bir sorun oluştu ve anket silinemedi!",
            variant: "danger",
          },
        });
      }
    },

    getSectionsOfSelectedSurvey() {
      if (!this.selectedSurvey) {
        return [];
      }
      var titleList = [];
      this.selectedSurvey.user_answers.forEach((userAnswer) => {
        if (!titleList.includes(userAnswer.title)) {
          titleList.push(userAnswer.title);
        }
      });
      var sections = [];
      titleList.forEach((title) => {
        sections.push({
          title: title,
          user_answers: [],
        });
      });
      this.selectedSurvey.user_answers.forEach((userAnswer) => {
        for (var i = 0; i < sections.length; i++) {
          if (sections[i].title == userAnswer.title) {
            sections[i].user_answers.push(userAnswer);
          }
        }
      });
      return sections;
    },

    async showSurveyDetails(surveys) {
      this.selectedSurvey = null;
      var survey = surveys[0];
      if (survey) {
        var surveyId = survey.ts_id;
        this.selectedSurvey = await store.dispatch(
          "adminCadreSurveys/fetchSurvey",
          surveyId
        );
        var giftType = this.selectedSurvey.gift_type;
        if (giftType && giftType.includes("101")) {
          var a101Gift = "Kart No: \n" + "Pin Kodu: ";
          if (
            !this.selectedSurvey.gift_code ||
            this.selectedSurvey.gift_code.length < 10
          ) {
            this.selectedSurvey.gift_code = a101Gift;
          } else {
            a101Gift = this.selectedSurvey.gift_code;
          }
          this.message =
            "Anketiniz onaylandı. Desteğiniz için teşekkür ederiz.\n\n" +
            this.selectedSurvey.gift_type +
            "\n" +
            a101Gift +
            "\n";
        } else {
          this.message =
            "Anketiniz onaylandı ve tüm kullanıcılarımıza ücretsiz olarak sunulacaktır. Desteğiniz için teşekkürler.";
        }
        if (this.isUpdatedSurvey(survey)) {
          this.message =
            "Anket güncellemeniz onaylandı.\nDesteğiniz için teşekkür ederiz.\n";
        }
      }
    },

    dateTimeToDayText(dateTime) {
      if (dateTime && dateTime.trim().length == 19) {
        dateTime = dateTime.trim();
        var date = dateTime.split(" ")[0];
        var year = date.split("-")[0];
        var month = date.split("-")[1];
        var day = date.split("-")[2];
        if (dateTime.slice(0, 10) == new Date().toISOString().slice(0, 10)) {
          return "Bugün";
        }
        switch (month) {
          case "01":
            return day + " Ocak " + year;
          case "02":
            return day + " Şubat " + year;
          case "03":
            return day + " Mart " + year;
          case "04":
            return day + " Nisan " + year;
          case "05":
            return day + " Mayıs " + year;
          case "06":
            return day + " Haziran " + year;
          case "07":
            return day + " Temmuz " + year;
          case "08":
            return day + " Ağustos " + year;
          case "09":
            return day + " Eylül " + year;
          case "10":
            return day + " Ekim " + year;
          case "11":
            return day + " Kasım " + year;
          case "12":
            return day + " Aralık " + year;
        }
      }
      return "-";
    },

    intitutionName(survey) {
      var instName = survey.institution.replace(survey.city, "").trim();
      instName = instName.split("/").join(" / ");
      instName = instName.split("ÜNİVERSİTESİ").join("ÜNİ.");
      instName = instName
        .split("EĞİTİM VE ARAŞTIRMA HASTANESİ")
        .join("EĞT. ARŞ. HAST.");
      instName = instName.split("HASTANESİ").join("HAST.");
      instName = instName.split("TIP FAKÜLTESİ").join("T.F.");
      instName = instName.split("SAĞLIK BİLİMLERİ").join("SAĞ. BİL.");
      instName = instName.split("KUVVETLERİ KOMUTANLIĞI").join("KUV. KOM.");
      instName = instName.split("EĞİTİM VE ARAŞTIRMA HASTANESİ").join("EAH");
      return (
        "<strong>" +
        this.toTitleCase(survey.city) +
        "</strong> " +
        this.toTitleCase(instName)
      );
    },
    toTitleCase(str) {
      return !str
        ? ""
        : str
            .replace("(", "( ")
            .toLocaleLowerCase("tr-TR")
            .replace(
              /(?:^|\s|,|;|!|:|-|\.|\?)[a-z0-9ğçşüöı]/g,
              function (match) {
                return match.toLocaleUpperCase("tr-TR");
              }
            )
            .replace("( ", "(");
    },
  },
  async created() {
    let moduleId = "cadre_surveys";
    let moduleName = "Kadro Anketleri"; 
    if(JSON.parse(localStorage.getItem("adminObject"))[moduleId] != 1){
      this.$router.push("/erisim-kapali?moduleName="+moduleName);
      return; 
    } 

    await store.dispatch("adminCadreSurveys/fetchSurveys");
    this.surveyList = store.state.adminCadreSurveys.surveyList;

    
    const checkKey = (e) => {
      e = e || window.event;
      if (
        e.shiftKey &&
        (e.key === "ArrowLeft" || e.key === "ArrowUp") &&
        this.resultsCurrentPage > 1
      ) {
        this.resultsCurrentPage--;
      } else if (
        e.shiftKey &&
        (e.key === "ArrowRight" || e.key === "ArrowDown") &&
        this.resultsCurrentPage <
          Math.ceil(this.surveyList.length / this.resultsPerPage)
      ) {
        this.resultsCurrentPage++;
      }
    };

    window.addEventListener('keydown', checkKey);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('keydown', checkKey);
    });

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@media only screen and (min-width: 1080px) {
  .modal-lg {
    max-width: 1024px;
  }
}

#admin-surveys .table th,
[dir] .table td {
  padding: 0.5rem 1.5rem;
}
</style>
